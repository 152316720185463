import React from 'react';

import { TAG_WIDGET } from 'common/redux/commonData/widgets/topProjectWidget/typings';

import { TagWidget } from '../TagWidget';
import { TAG_WIDGET_TYPE } from '../TagWidget/typings';

/**
 * Виджет Фильм недели
 */
export const WeekFilmWidget = () => (
  <TagWidget
    widgetTitle="Фильм недели"
    widgetIcon={TAG_WIDGET_TYPE.weekFilmWidget}
    widgetType={TAG_WIDGET.Mnenia}
    widgetTop100Value="week-film"
    withImage
    isVisibleWithoutJS
  />
);
