import React, { forwardRef } from 'react';

import { PROJECT_ALIAS_BY_ID } from 'config/constants/projects/constants';
import { RCM_BLOCK_TYPE } from 'config/constants/rcm';

import { TopProjectNewsWidgetCompactBase } from './components/Base';
import { TopProjectNewsWidgetCompactBaseWithRec } from './components/RecBase';
import { useTopProjectNewsWidget } from './hooks/useTopProjectNewsWidget';

type TopProjectNewsWidgetCompactType = {
  projectId: keyof typeof PROJECT_ALIAS_BY_ID;
  index: number;
  rcmBlockType?: RCM_BLOCK_TYPE;
  onClick?: () => void;
};

/**
 * Виджет топа новостей проекта.
 * @param props.projectId – id вертикали запрашиваемого топа;
 * @param props.index – индекс отображаемого кластера из топа;
 * @param props.rcmBlockType - значение к какому типу страниц с рекомендациями относится данный блок;
 * @param props.onClick - функция клика по айтему.
 */
export const TopProjectNewsWidgetCompact = forwardRef<
  HTMLDivElement,
  TopProjectNewsWidgetCompactType
>(({ projectId, index, rcmBlockType, onClick }, ref) => {
  const { cluster, isError, fetchData } = useTopProjectNewsWidget(
    projectId,
    index,
    true,
  );

  if (!cluster) return null;

  const {
    id,
    title: clusterTitle,
    publicationTime,
    url: clusterUrl,
    commentsCount,
    topic,
    noComments,
  } = cluster;

  if (rcmBlockType) {
    return (
      <TopProjectNewsWidgetCompactBaseWithRec
        index={index}
        isError={isError}
        onRefresh={fetchData}
        clusterId={id}
        clusterCommentsCount={commentsCount}
        noComments={noComments}
        clusterPublicationTime={publicationTime}
        clusterTitle={clusterTitle}
        clusterTopic={topic}
        clusterUrl={clusterUrl}
        projectId={projectId}
        rcmKey={rcmBlockType}
        contextItemId={rcmBlockType}
        onClick={onClick}
        ref={ref}
      />
    );
  }

  return (
    <TopProjectNewsWidgetCompactBase
      index={index}
      isError={isError}
      onRefresh={fetchData}
      clusterId={id}
      clusterCommentsCount={commentsCount}
      noComments={noComments}
      clusterPublicationTime={publicationTime}
      clusterTitle={clusterTitle}
      clusterTopic={topic}
      clusterUrl={clusterUrl}
      projectId={projectId}
      onClick={onClick}
      ref={ref}
    />
  );
});
