import React from 'react';

import { RCM_BLOCK_TYPE } from 'config/constants/rcm';
import { DoctorAnswerWidget } from 'desktop/components/DoctorAnswerWidget';
import { HotNewsWidget } from 'desktop/components/HotNewsWidget';
import { OpinionsWidget } from 'desktop/components/OpinionsWidget';
import { PsychAdviceWidget } from 'desktop/components/PsychAdviceWidget';
import { RecipesWidget } from 'desktop/components/RecipesWidget';
import { TopProjectNewsWidgetCompact } from 'desktop/components/TopNewsWidgetCompact';
import { WeekFilmWidget } from 'desktop/components/WeekFilmWidget';

import { WidgetsType } from './typings';

type WidgetsConfigType = (
  | WidgetsType
  | {
      projectId: ProjectType['id'];
      index: number;
      rcmBlockType?: RCM_BLOCK_TYPE;
    }
)[];

type RenderWidgetsListType = (
  widgetsConfig: WidgetsConfigType,
) => React.ReactNode[];

/**
 * Функция, которая возвращает массив виджетов, собранный через конфиг.
 * @param widgetsConfig - конфиг для рендера виджетов.
 */
export const renderWidgetsList: RenderWidgetsListType = (widgetsConfig) =>
  widgetsConfig.map((config) => {
    if (config === WidgetsType.Hotnews) {
      return <HotNewsWidget key="hotNewsWidget" />;
    }

    if (config === WidgetsType.Opinions) {
      return <OpinionsWidget key="opinionsWidget" />;
    }

    if (config === WidgetsType.DoctorAnswer) {
      return <DoctorAnswerWidget key="doctorAnswerWidget" />;
    }

    if (config === WidgetsType.WeekFilm) {
      return <WeekFilmWidget key="weekFilmWidget" />;
    }

    if (config === WidgetsType.PsychAdvice) {
      return <PsychAdviceWidget key="psychAdviceWidget" />;
    }

    if (config === WidgetsType.Recipes) {
      return <RecipesWidget key="recipesWidget" />;
    }

    if ('projectId' in config && 'index' in config) {
      return (
        <TopProjectNewsWidgetCompact
          key={`${config.projectId}_${config.index}`}
          projectId={config.projectId}
          index={config.index}
          rcmBlockType={config.rcmBlockType}
        />
      );
    }

    return null;
  });
