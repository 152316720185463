import React, { memo } from 'react';

import { TAG_WIDGET } from 'common/redux/commonData/widgets/topProjectWidget/typings';

import { TagWidget } from '../TagWidget';
import { TAG_WIDGET_TYPE } from '../TagWidget/typings';

type OpinionsWidgetPropsType = {
  customWidgetTitle?: string;
};

/**
 * Виджет мнений
 */
export const OpinionsWidget = memo(
  ({ customWidgetTitle = 'Мнения' }: OpinionsWidgetPropsType) => (
    <TagWidget
      widgetTitle={customWidgetTitle}
      widgetIcon={TAG_WIDGET_TYPE.opinionsWidget}
      widgetType={TAG_WIDGET.Mnenia}
      widgetTop100Value="opinions"
      withExpert
      isVisibleWithoutJS
    />
  ),
);
