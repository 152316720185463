import React, { memo } from 'react';

import { PROJECT_IDS } from 'config/constants/projects/constants';
import { WidgetsListBase } from 'desktop/components/FirstPageWithWidgetsByTop/WidgetsColumn/components/Base';
import {
  WidgetsColumnPropsType,
  WidgetsType,
} from 'desktop/components/FirstPageWithWidgetsByTop/WidgetsColumn/typings';
import { renderWidgetsList } from 'desktop/components/FirstPageWithWidgetsByTop/WidgetsColumn/utils';

const widgetsConfig = [
  WidgetsType.PsychAdvice,
  { projectId: PROJECT_IDS.Weekend, index: 0 },
  { projectId: PROJECT_IDS.Weekend, index: 1 },
  { projectId: PROJECT_IDS.Weekend, index: 2 },
  WidgetsType.Recipes,
  { projectId: PROJECT_IDS.Doctor, index: 0 },
  { projectId: PROJECT_IDS.Doctor, index: 1 },
];

export const WomanWidgetsHome = memo(({ puids }: WidgetsColumnPropsType) => (
  <WidgetsListBase puids={puids}>
    {renderWidgetsList(widgetsConfig)}
  </WidgetsListBase>
));
