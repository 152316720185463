import { PuidsType } from 'config/constants/common';

export enum WidgetsType {
  Hotnews = 'hotnews',
  Opinions = 'opinions',
  DoctorAnswer = 'doctorAnswer',
  WeekFilm = 'weekFilm',
  PsychAdvice = 'psychAdvice',
  Recipes = 'recipes',
}

export type WidgetsColumnPropsType = {
  puids: PuidsType;
};
