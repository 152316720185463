import cn from 'classnames';
import React, { memo } from 'react';
import { shallowEqual } from 'react-redux';

import { RamblerImage } from 'common/components/RamblerComponents/RamblerImage';
import { useTop100AttributeWithValue } from 'common/hooks/useTop100Attribute';
import { selectExpertById } from 'common/redux/commonData/experts/selectors';
import { selectClusterPageTextScale } from 'common/redux/pages/cluster/selectors';
import {
  selectDomainConfig,
  selectProjectId,
} from 'common/redux/runtime/selectors';
import { getExpertLink } from 'common/utils/getExpertLink';
import { useAppSelector } from 'store/hooks';

import s from './styles.module.css';

export const DEFAULT_IMG =
  'https://news.rambler.ru/special/expert-avatar-default.png';

type ExpertInfoPropsType = {
  expertId: ClusterResourceType['id'];
};

/**
 * Компонент информации об эксперте для вставки в цитату
 * @param expertId - id эксперта
 */
export const ExpertInfo = memo(({ expertId }: ExpertInfoPropsType) => {
  const expert = useAppSelector(selectExpertById(expertId), shallowEqual);
  const textScaleValue = useAppSelector(selectClusterPageTextScale);
  const domainConfig = useAppSelector(selectDomainConfig, shallowEqual);
  const projectId = useAppSelector(selectProjectId);

  const top100Attribute = useTop100AttributeWithValue('expert_quote');

  if (!expert) return null;

  const { alias, image, name, specialization } = expert;
  const imageUrl = image ? image.url : DEFAULT_IMG;
  const url = getExpertLink({ domainConfig, projectId, alias });

  return (
    <div className={s.expertInfo}>
      <RamblerImage
        width={50}
        height={50}
        src={imageUrl}
        isS3={image?.s3}
        className={s.image}
        alt={name}
        isLazy
      />
      <div className={cn(s.expertTitle, s[`expertTitle_${textScaleValue}`])}>
        <a
          href={url}
          aria-label={`Открыть информацию об эксперте ${name}`}
          className={s.name}
          {...top100Attribute}
        >
          {name}
        </a>
        {!!specialization && (
          <span className={s.specialization}>{specialization}</span>
        )}
      </div>
    </div>
  );
});
