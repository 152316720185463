import React, { memo } from 'react';

import { PROJECT_IDS } from 'config/constants/projects/constants';
import { WidgetsListBase } from 'desktop/components/FirstPageWithWidgetsByTop/WidgetsColumn/components/Base';
import {
  WidgetsColumnPropsType,
  WidgetsType,
} from 'desktop/components/FirstPageWithWidgetsByTop/WidgetsColumn/typings';
import { renderWidgetsList } from 'desktop/components/FirstPageWithWidgetsByTop/WidgetsColumn/utils';

const widgetsConfig = [
  { projectId: PROJECT_IDS.News, index: 0 },
  { projectId: PROJECT_IDS.News, index: 1 },
  { projectId: PROJECT_IDS.News, index: 2 },
  { projectId: PROJECT_IDS.Auto, index: 0 },
  WidgetsType.Opinions,
  { projectId: PROJECT_IDS.Auto, index: 1 },
  { projectId: PROJECT_IDS.Auto, index: 2 },
];

export const SportWidgetsHome = memo(({ puids }: WidgetsColumnPropsType) => (
  <WidgetsListBase puids={puids}>
    {renderWidgetsList(widgetsConfig)}
  </WidgetsListBase>
));
