import React, { memo } from 'react';

import { useBlockInitAtd } from 'common/hooks/useRcm';
import { PROJECT_IDS } from 'config/constants/projects/constants';
import { RCM_BLOCK_TYPE } from 'config/constants/rcm';
import { WidgetsListBase } from 'desktop/components/FirstPageWithWidgetsByTop/WidgetsColumn/components/Base';
import {
  WidgetsColumnPropsType,
  WidgetsType,
} from 'desktop/components/FirstPageWithWidgetsByTop/WidgetsColumn/typings';
import { renderWidgetsList } from 'desktop/components/FirstPageWithWidgetsByTop/WidgetsColumn/utils';

const widgetsConfig = [
  {
    projectId: PROJECT_IDS.Doctor,
    index: 0,
    rcmBlockType: RCM_BLOCK_TYPE.topNewsWidgetDesktop,
  },
  {
    projectId: PROJECT_IDS.Doctor,
    index: 1,
    rcmBlockType: RCM_BLOCK_TYPE.topNewsWidgetDesktop,
  },
  {
    projectId: PROJECT_IDS.Doctor,
    index: 2,
    rcmBlockType: RCM_BLOCK_TYPE.topNewsWidgetDesktop,
  },
  {
    projectId: PROJECT_IDS.Doctor,
    index: 3,
    rcmBlockType: RCM_BLOCK_TYPE.topNewsWidgetDesktop,
  },
  WidgetsType.DoctorAnswer,
  {
    projectId: PROJECT_IDS.Doctor,
    index: 4,
    rcmBlockType: RCM_BLOCK_TYPE.topNewsWidgetDesktop,
  },
  {
    projectId: PROJECT_IDS.Doctor,
    index: 5,
    rcmBlockType: RCM_BLOCK_TYPE.topNewsWidgetDesktop,
  },
];

export const DoctorWidgetsTopic = memo(({ puids }: WidgetsColumnPropsType) => {
  useBlockInitAtd(RCM_BLOCK_TYPE.topNewsWidgetDesktop);

  return (
    <WidgetsListBase puids={puids}>
      {renderWidgetsList(widgetsConfig)}
    </WidgetsListBase>
  );
});
