import cn from 'classnames';
import React, { memo, useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { selectWidgetsTopProjectFirstClusterIDInCardData } from 'common/redux/commonData/widgets/topProjectWidget/selectors';
import { TAG_WIDGET } from 'common/redux/commonData/widgets/topProjectWidget/typings';
import { FeedWidgetLayout } from 'desktop/components/FeedWidgetLayout';
import {
  DoctorAnswerIcon,
  HotNewsIcon,
  OpinionsIcon,
  PsyhologyIcon,
  RecipesIcon,
  WeekFilmIcon,
} from 'icons';
import { SVGElementType } from 'icons/typings';

import { ExpertBlock } from './components/ExpertBlock';
import { ImageBlock } from './components/ImageBlock';
import { MetaBlock } from './components/MetaBlock';
import { TAG_WIDGET_TYPE } from './typings';

import s from './styles.module.css';

const TAG_WIDGET_ICONS: { [key in TAG_WIDGET_TYPE]: SVGElementType } = {
  [TAG_WIDGET_TYPE.doctorAnswerWidget]: DoctorAnswerIcon,
  [TAG_WIDGET_TYPE.hotNewsWidget]: HotNewsIcon,
  [TAG_WIDGET_TYPE.opinionsWidget]: OpinionsIcon,
  [TAG_WIDGET_TYPE.psychAdviceWidget]: PsyhologyIcon,
  [TAG_WIDGET_TYPE.recipesWidget]: RecipesIcon,
  [TAG_WIDGET_TYPE.weekFilmWidget]: WeekFilmIcon,
};

type TagWidgetPropsType = {
  widgetTitle: string;
  widgetIcon: TAG_WIDGET_TYPE;
  widgetType: TAG_WIDGET;
  widgetTop100Value: string;
  withExpert?: boolean;
  withOutline?: boolean;
  withImage?: boolean;
  withMeta?: boolean;
  isVisibleWithoutJS?: boolean;
};

/**
 * Базовый виджет тэга.
 * @param props.widgetTitle - заголовок виджета;
 * @param props.widgetIconId - строчка с id svg-шки иконки;
 * @param props.widgetType - тип виджета, для которого извлекается кластер;
 * @param props.widgetTop100Value - строчка со значением top100 блока;
 * @param props.withExpert - флаг, что надо показывать эксперта;
 * @param props.withOutline - флаг, что надо делать обводку виджета;
 * @param props.withImage - флаг, что надо показывать изображение в виджете;
 * @param props.withMeta - флаг, что надо показывать мета* информацию;
 * @param props.isVisibleWithoutJS - флаг, что виджет виден без js.
 */
export const TagWidget = memo(
  ({
    widgetTitle,
    widgetIcon,
    widgetType,
    widgetTop100Value,
    withExpert,
    withOutline,
    withImage,
    withMeta,
    isVisibleWithoutJS,
  }: TagWidgetPropsType) => {
    const cluster = useSelector(
      selectWidgetsTopProjectFirstClusterIDInCardData(widgetType),
      shallowEqual,
    );

    const ComponentIcon = TAG_WIDGET_ICONS[widgetIcon];

    const widgetTitleComponent = useMemo(
      () => (
        <>
          <ComponentIcon className={s.icon} />
          <span>{widgetTitle}</span>
        </>
      ),
      [widgetTitle, ComponentIcon],
    );

    if (!cluster) return null;

    const { url, title, image, commentsCount, publicationTime, topic } =
      cluster;

    return (
      <FeedWidgetLayout
        title={widgetTitleComponent}
        top100Value={widgetTop100Value}
        styles={s}
        className={cn(withImage && s.withImage, withOutline && s.withOutline)}
        isVisibleWithoutJS={isVisibleWithoutJS}
        shouldWrapInUL={false}
      >
        {withImage && <ImageBlock image={image} className={s.image} />}
        <article className={s.item}>
          <a className={s.itemLink} href={url}>
            <p>{title}</p>
          </a>

          {withExpert && <ExpertBlock widgetType={widgetType} />}
          {withMeta && (
            <MetaBlock
              commentsCount={commentsCount}
              publicationTime={publicationTime}
              topicName={topic}
            />
          )}
        </article>
      </FeedWidgetLayout>
    );
  },
);

/* *запрещенная в РФ организация */
