import React, { memo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { DEFAULT_IMG } from 'common/components/ExpertQuote/ExpertInfo';
import { RamblerImage } from 'common/components/RamblerComponents/RamblerImage';
import { selectWidgetsTopProjectClusterExpertInfoByIndex } from 'common/redux/commonData/widgets/topProjectWidget/selectors';
import { TAG_WIDGET } from 'common/redux/commonData/widgets/topProjectWidget/typings';

import s from './styles.module.css';

const PICTURE_WIDTH = 20;
const PICTURE_HEIGHT = 20;

type ExpertBlockPropsType = {
  widgetType: TAG_WIDGET;
};

/**
 * Компонент блока с данными эксперта.
 * @param widgetType - тип отображаемого виджета.
 */
export const ExpertBlock = memo(({ widgetType }: ExpertBlockPropsType) => {
  const clusterExperts = useSelector(
    selectWidgetsTopProjectClusterExpertInfoByIndex(0, widgetType),
    shallowEqual,
  );
  const { name, image } = clusterExperts?.[0] ?? {};

  if (!clusterExperts?.[0]) return null;

  return (
    <p className={s.root}>
      <RamblerImage
        height={PICTURE_HEIGHT}
        width={PICTURE_WIDTH}
        src={image?.url ?? DEFAULT_IMG}
        alt={image?.description ?? ''}
        isS3={!!image?.s3}
        className={s.image}
        isLazy
      />

      {name}
    </p>
  );
});
