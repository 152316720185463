import React from 'react';

import { TAG_WIDGET } from 'common/redux/commonData/widgets/topProjectWidget/typings';

import { TagWidget } from '../TagWidget';
import { TAG_WIDGET_TYPE } from '../TagWidget/typings';

/**
 * Виджет Совет психолога
 */
export const PsychAdviceWidget = () => (
  <TagWidget
    widgetTitle="Совет психолога"
    widgetIcon={TAG_WIDGET_TYPE.psychAdviceWidget}
    widgetType={TAG_WIDGET.HotNews}
    widgetTop100Value="psychAdvice"
    withExpert
    isVisibleWithoutJS
  />
);
