import React from 'react';

import { TAG_WIDGET } from 'common/redux/commonData/widgets/topProjectWidget/typings';

import { TagWidget } from '../TagWidget';
import { TAG_WIDGET_TYPE } from '../TagWidget/typings';

/**
 * Виджет Горячая новость
 */
export const HotNewsWidget = () => (
  <TagWidget
    widgetTitle="Горячая новость"
    widgetIcon={TAG_WIDGET_TYPE.hotNewsWidget}
    widgetType={TAG_WIDGET.HotNews}
    widgetTop100Value="hotnews"
    withOutline
    withMeta
    isVisibleWithoutJS
  />
);
