import React, { MutableRefObject, memo } from 'react';

import { CommentsCounter } from 'common/components/CommentsCounter';
import { datesince } from 'utils/date/dtime';

import s from './styles.module.css';

export type ItemPropsType = {
  url: CardData['url'];
  title: CardData['title'];
  topic: CardData['topic'];
  publicationTime: CardData['publicationTime'];
  count: number | undefined;
  noComments: CardData['noComments'];
  linkRef?: MutableRefObject<HTMLAnchorElement>;
  onClick?: () => void;
};

/**
 * Компонент новости для виджета топа новостей.
 * @param props.url - ссылка на кластер;
 * @param props.title - заголовок кластера;
 * @param props.topic - топик кластера;
 * @param props.publicationTime - время публикации;
 * @param props.count - количество комментариев в кластере;
 * @param props.noComments - флаг принудительного отключения комментариев в кластере;
 * @param props.linkRef - реф для ссылки;
 * @param props.onClick - событие по клику для ссылки.
 */
export const Item = memo(
  ({
    url,
    title,
    publicationTime,
    count,
    noComments,
    topic,
    linkRef,
    onClick,
  }: ItemPropsType) => (
    <span className={s.item}>
      <a href={url} ref={linkRef} onClick={onClick}>
        {title}
      </a>
      <p className={s.meta}>
        <span className={s.topic}>{topic}</span>
        <time dateTime={publicationTime} className={s.time}>
          {!!publicationTime &&
            datesince({
              pubdate: new Date(publicationTime).getTime() / 1000,
              isShort: true,
            })}
        </time>
        {!noComments && (
          <CommentsCounter count={count} className={s.comments} />
        )}
      </p>
    </span>
  ),
);
