import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import { selectPageName } from 'common/redux/appController/selectors';
import { PAGE_TYPE } from 'config/constants/routerName';

import { WidgetsColumnPropsType } from '../../typings';

import { SportWidgetsHome } from './components/SportWidgetsHome';
import { SportWidgetsTopic } from './components/SportWidgetsTopic';

/**
 * Список виджетов для второй колонки для вертикали Спорт
 * @param puids – объект пуидов для рекламы
 */
export const SportWidgets = memo(({ puids }: WidgetsColumnPropsType) => {
  const pageName = useSelector(selectPageName);

  switch (pageName) {
    case PAGE_TYPE.home:
      return <SportWidgetsHome puids={puids} />;
    case PAGE_TYPE.topic:
      return <SportWidgetsTopic puids={puids} />;

    default:
      return <SportWidgetsHome puids={puids} />;
  }
});
