import cn from 'classnames';
import React, { memo } from 'react';

import { RamblerImage } from 'common/components/RamblerComponents/RamblerImage';

import s from './styles.module.css';

const PICTURE_WIDTH = 75;
const PICTURE_HEIGHT = 75;
const DEFAULT_DESCRIPTION = 'Изображение виджета';

type ImageBlockPropsType = {
  image: CardData['image'];
  className?: string;
};

/**
 * Блок с изображением кластера.
 * @param props.image - объект изображения;
 * @param props.className - строчка с классами для стилизации компонента.
 */
export const ImageBlock = memo(({ image, className }: ImageBlockPropsType) => {
  if (!image?.url)
    return (
      <div
        className={cn(s.root, className)}
        aria-label={image?.description ?? DEFAULT_DESCRIPTION}
      />
    );

  return (
    <RamblerImage
      height={PICTURE_HEIGHT}
      width={PICTURE_WIDTH}
      src={image?.url}
      alt={image?.description ?? DEFAULT_DESCRIPTION}
      isS3={!!image?.s3}
      className={cn(s.root, className)}
      isLazy
    />
  );
});
