import React, { useCallback, MutableRefObject, forwardRef } from 'react';

import { RcmCardType, RecEvents } from 'common/RecEvents';

import { TopProjectNewsWidgetBasePropsType } from '../Base';

import { RenderWrapper } from './components/RenderWrapper';

type TopProjectNewsWidgetCompactBaseWithRecPropsType = {
  clusterId: CardData['id'];
  rcmKey: RcmCardType['rcmKey'];
  contextItemId: RcmCardType['contextItemId'];
} & TopProjectNewsWidgetBasePropsType;

/**
 * Обертка над обычным виджетом рекомендованной новости, добавляющей отслеживание и отправку событий для рекомендаций.
 * @param props.rcmKey — ключ, по которому храним blockID в конфигурации (topNow например);
 * @param props.contextItemId - идентификатор страницы, на которой используется блок (clusterId, pageName или еще что-то);
 * @param props.projectId - id проекта, по которому извлекаются новости;
 * @param props.index - положение карточки в топе.
 */
export const TopProjectNewsWidgetCompactBaseWithRec = forwardRef<
  HTMLDivElement,
  TopProjectNewsWidgetCompactBaseWithRecPropsType
>(
  (
    {
      rcmKey,
      contextItemId,
      projectId,
      index,
      isError,
      clusterId,
      clusterCommentsCount,
      noComments,
      clusterPublicationTime,
      clusterTopic,
      clusterUrl,
      clusterTitle,
      onRefresh,
      onClick,
    },
    ref,
  ) => {
    const render = useCallback(
      (recRef: MutableRefObject<HTMLDivElement>, _onClick: () => void) => (
        <RenderWrapper
          recRef={recRef}
          ref={ref}
          isError={isError}
          clusterId={clusterId}
          clusterCommentsCount={clusterCommentsCount}
          noComments={noComments}
          clusterPublicationTime={clusterPublicationTime}
          clusterTitle={clusterTitle}
          clusterTopic={clusterTopic}
          projectId={projectId}
          clusterUrl={clusterUrl}
          index={index}
          onClick={onClick}
          recOnClick={_onClick}
          onRefresh={onRefresh}
        />
      ),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [
        clusterCommentsCount,
        clusterId,
        clusterPublicationTime,
        clusterTitle,
        clusterTopic,
        clusterUrl,
        index,
        noComments,
        isError,
        noComments,
        onClick,
        onRefresh,
        projectId,
        ref,
      ],
    );

    return (
      <RecEvents
        clusterId={clusterId}
        rcmKey={rcmKey}
        position={index}
        contextItemId={contextItemId}
        // @ts-expect-error: ¯\_(ツ)_/¯
        render={render}
      />
    );
  },
);
