import React, { memo } from 'react';

import { CommentsCounter } from 'common/components/CommentsCounter';
import { datesince } from 'utils/date/dtime';

import s from './styles.module.css';

type MetaBlockPropsType = {
  topicName: string | undefined | null;
  publicationTime: string | undefined;
  commentsCount: number | undefined;
};

/**
 * Блок с метаинформацией
 * @param props.topicName - название рубрики кластера;
 * @param props.publicationTime - дата публикации кластера строкой;
 * @param props.commentsCount - счетчик комментариев.
 */
export const MetaBlock = memo(
  ({ topicName, publicationTime, commentsCount }: MetaBlockPropsType) => {
    const formattedDate = publicationTime
      ? datesince({
          pubdate: new Date(publicationTime).getTime() / 1000,
          isShort: true,
        })
      : null;

    return (
      <span className={s.root}>
        <span>{topicName}</span>
        <span>{formattedDate}</span>
        <CommentsCounter count={commentsCount} className={s.icon} />
      </span>
    );
  },
);
