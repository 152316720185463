import { getAbsoluteUrl } from 'utils/urlGenerator';

type GetExpertLink = ({
  domainConfig,
  projectId,
  alias,
}: {
  domainConfig: DomainConfigType;
  projectId: ProjectType['id'];
  alias: ATExpertType['alias'];
}) => string;

/**
 * Функция генерации ссылки на страницу эксперта
 * @param props.domainConfig - объект данных о домене;
 * @param props.projectId - id проекта;
 * @param props.alias - никнейм эксперта.
 */
export const getExpertLink: GetExpertLink = ({
  domainConfig,
  projectId,
  alias,
}) => getAbsoluteUrl({ domainConfig, projectId, url: `/experts/${alias}/` });
