import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import { selectPageName } from 'common/redux/appController/selectors';
import { PAGE_TYPE } from 'config/constants/routerName';

import { WidgetsColumnPropsType } from '../../typings';

import { WomanWidgetsHome } from './components/WomanWidgetsHome';
import { WomanWidgetsTopic } from './components/WomanWidgetsTopic';

/**
 * Список виджетов для второй колонки для вертикали Женский
 * @param puids – объект пуидов для рекламы
 */
export const WomanWidgets = memo(({ puids }: WidgetsColumnPropsType) => {
  const pageName = useSelector(selectPageName);

  switch (pageName) {
    case PAGE_TYPE.home:
      return <WomanWidgetsHome puids={puids} />;
    case PAGE_TYPE.topic:
      return <WomanWidgetsTopic puids={puids} />;

    default:
      return <WomanWidgetsHome puids={puids} />;
  }
});
